export const toLocaleDate = (originalDate: string | Date) => {
  const date = new Date(originalDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so add 1
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const toDate = (originalDate: string | Date) => {
  return new Date(originalDate);
};

export const toYearMonthDate = (originalDate: string | Date) => {
  const date = new Date(originalDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so add 1

  return `${year}-${month}`;
};

export const aWeekFrom = (from: Date) => new Date(from.setDate(from.getDate() + 7));

export const aMonthFrom = (from: Date) => new Date(from.setMonth(from.getMonth() + 1));
